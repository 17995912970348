.mediaPage{
    padding: 100px 0px;
    background : url(../../assets/images/backgrounds/inner-bg.jpg) center center
}
.gameDescription{
    color: #7b76b5 !important;
    span{
        color: #7b76b5 !important;
    }
    a{
        display: inline-block;
    }
}
.newsbox {
    background: #1a1744;
    margin-bottom: 15px;
    border-radius: 5px;
    padding: 15px;
    .mediaTitle{
        margin: 0 0 20px;
        padding: 0;
        color: #ff9800;
        font-size: 1.9rem;
        font-weight: 500;
    }
    .newsboxDescription {
        color: #8d88c3 ;
        font-size: .9rem;

        display: -webkit-box;
        -webkit-line-clamp: 5;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        a{
            display: inline-block !important;
        }
        p{
            color: #8d88c3 ;

        }
        span{
            color: #8d88c3 !important;

        }
    }
}