.contactPage{
    background: url(../../assets/images/backgrounds/game-bg.jpg) center center;
    background-size: cover;
    position: relative;
    padding: 140px 0;
    background-attachment: fixed;
}

.registerDescription{
    color: #8d88c3;
}