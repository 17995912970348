.calltoaction{
  background: radial-gradient(circle at 33% 100%,#f9d075 4%,#fc584a 30%,#d0367f 62%,#941cb5 85%,#515ECF);
  padding-bottom: 0;
  padding-top: 60px;
  position: relative;
  z-index: 2;
  a{
    color: #181550;
      font-size: 24px;
      font-weight: 600;
      letter-spacing: 3px;
  }
  .calltoactionText{
    color: #FFF;
    margin-bottom: 0px;
  }
}

.downloadPhone{
  top: 40px;
  position: relative;
}

/* @import url(https://fonts.googleapis.com/css?family=Open+Sans); */

/* psuedo element animations */
@-webkit-keyframes downloadBounce {
    to {
      -webkit-transform: translateY(0px) rotate(50deg);
    }
  }
  
  @-moz-keyframes downloadBounce {
    to {
      -moz-transform: translateY(0px) rotate(50deg);
    }
  }
  
  @keyframes downloadBounce {
    to {
      -webkit-transform: translateY(0px) rotate(50deg);
      -moz-transform: translateY(0px) rotate(50deg);
      -ms-transform: translateY(0px) rotate(50deg);
      -o-transform: translateY(0px) rotate(50deg);
      transform: translateY(0px) rotate(50deg);
    }
  }
  
  @-webkit-keyframes downloadBounce2 {
    to {
      -webkit-transform: translateY(0px) rotate(-50deg);
    }
  }
  
  @-moz-keyframes downloadBounce2 {
    to {
      -moz-transform: translateY(0px) rotate(-50deg);
    }
  }
  
  @keyframes downloadBounce2 {
    to {
      -webkit-transform: translateY(0px) rotate(-50deg);
      -moz-transform: translateY(0px) rotate(-50deg);
      -ms-transform: translateY(0px) rotate(-50deg);
      -o-transform: translateY(0px) rotate(-50deg);
      transform: translateY(0px) rotate(-50deg);
    }
  }
  
  
  .download {
    /* rounded corner overflow mask fix */
    -webkit-mask-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAIAAACQd1PeAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAA5JREFUeNpiYGBgAAgwAAAEAAGbA+oJAAAAAElFTkSuQmCC);
    overflow: hidden;
    border-radius: 5px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    /* background: #007ccd; */
    background: #fff;
    border: 2px solid #007ccd;
    color: white;
    padding: 26px 0 26px 28px;
    width: 220px;
    display: inline-block;
    position: relative;
    -webkit-transition: all 300ms ease;
    -moz-transition: all 300ms ease;
    transition: all 300ms ease;
    margin-right: 20px;
        border-radius: 15px;
  
  }
  
  .download span {
    display: block;
    position: relative;
    z-index: 1;
    -webkit-transform: translate(0, 0);
    -moz-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -o-transform: translate(0, 0);
    transform: translate(0, 0);
    text-align: center;
  }
  @media (max-width: 768px) {
    .download{
      width: 170px;
      padding:20px 0px;
    }
    .download span{
      font-size: 20px;
      margin-left: 42px;
    }
  }
  
  .download span:after,
  .download span:before {
    content: "";
    width: 1px;
    height: 16px;
    background: #007ccd;
    opacity: 0;
    position: absolute;
    left: 50%;
    top: 100%;
    -webkit-transition: all 10ms ease-out;
    -moz-transition: all 10ms ease-out;
    transition: all 10ms ease-out;
    -webkit-transform-origin: center bottom;
    -moz-transform-origin: center bottom;
    -ms-transform-origin: center bottom;
    -o-transform-origin: center bottom;
    transform-origin: center bottom;
    -webkit-transform: translateY(0) rotate(0deg);
    -moz-transform: translateY(0) rotate(0deg);
    -ms-transform: translateY(0) rotate(0deg);
    -o-transform: translateY(0) rotate(0deg);
    transform: translateY(0) rotate(0deg);
    -webkit-animation: 0;
    -moz-animation: 0;
    animation: 0;
  }
  
  .download svg {
    z-index: 0;
    /* fill: white; */
    fill: #181550;
    position: absolute;
    left: 20%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%) rotate(0deg) scale(0.04857);
    -moz-transform: translate(-50%, -50%) rotate(0deg) scale(0.04857);
    -ms-transform: translate(-50%, -50%) rotate(0deg) scale(0.04857);
    -o-transform: translate(-50%, -50%) rotate(0deg) scale(0.04857);
    transform: translate(-50%, -50%) rotate(0deg) scale(0.04857);
    -webkit-transition: all 350ms ease-out;
    -moz-transition: all 350ms ease-out;
    transition: all 350ms ease-out;
    height: 700px;
  }
  
  .download svg#linux {
    -webkit-transform: translate(-50%, -50%) rotate(0deg) scale(0.05857);
    -moz-transform: translate(-50%, -50%) rotate(0deg) scale(0.05857);
    -ms-transform: translate(-50%, -50%) rotate(0deg) scale(0.05857);
    -o-transform: translate(-50%, -50%) rotate(0deg) scale(0.05857);
    transform: translate(-50%, -50%) rotate(0deg) scale(0.05857);
  }
  
  .download:hover {
    padding: 26px 0;
    color: #007ccd;
  }
  
  .download:hover svg {
    left: 50%;
    -webkit-transform: translate(-50%, -50%) rotate(60deg) scale(1);
    -moz-transform: translate(-50%, -50%) rotate(60deg) scale(1);
    -ms-transform: translate(-50%, -50%) rotate(60deg) scale(1);
    -o-transform: translate(-50%, -50%) rotate(60deg) scale(1);
    transform: translate(-50%, -50%) rotate(60deg) scale(1);
  }
  
  .download:hover svg#windows {
    -webkit-transform: translate(-50%, -10%) rotate(60deg) scale(1);
    -moz-transform: translate(-50%, -10%) rotate(60deg) scale(1);
    -ms-transform: translate(-50%, -10%) rotate(60deg) scale(1);
    -o-transform: translate(-50%, -10%) rotate(60deg) scale(1);
    transform: translate(-50%, -10%) rotate(60deg) scale(1);
  }
  
  .download:hover svg#linux {
    -webkit-transform: translate(-120%, -125%) rotate(60deg) scale(6);
    -moz-transform: translate(-120%, -125%) rotate(60deg) scale(6);
    -ms-transform: translate(-120%, -125%) rotate(60deg) scale(6);
    -o-transform: translate(-120%, -125%) rotate(60deg) scale(6);
    transform: translate(-120%, -125%) rotate(60deg) scale(6);
  }
  
  .download:hover span {
    -webkit-transform: translate(0, -10px) scale(1.1);
    -moz-transform: translate(0, -10px) scale(1.1);
    -ms-transform: translate(0, -10px) scale(1.1);
    -o-transform: translate(0, -10px) scale(1.1);
    transform: translate(0, -10px) scale(1.1);
    -webkit-transition: -webkit-transform 400ms ease-out 400ms;
    -moz-transition: -moz-transform 400ms ease-out 400ms;
    transition: transform 400ms ease-out 400ms;
  }
  
  .download:hover span:after,
  .download:hover span:before {
    opacity: 1;
    -webkit-transition: all 400ms ease-out 400ms, -webkit-transform 300ms ease-in 100ms;
    -moz-transition: all 400ms ease-out 400ms, -moz-transform 300ms ease-in 100ms;
    transition: all 400ms ease-out 400ms, transform 300ms ease-in 100ms;
  }
  
  .download:hover span:after {
    -webkit-transform: translateY(7px) rotate(50deg);
    -moz-transform: translateY(7px) rotate(50deg);
    -ms-transform: translateY(7px) rotate(50deg);
    -o-transform: translateY(7px) rotate(50deg);
    transform: translateY(7px) rotate(50deg);
    -webkit-animation: downloadBounce 800ms linear alternate infinite;
    -moz-animation: downloadBounce 800ms linear alternate infinite;
    animation: downloadBounce 800ms linear alternate infinite;
  }
  
  .download:hover span:before {
    -webkit-transform: translateY(7px) rotate(-50deg);
    -moz-transform: translateY(7px) rotate(-50deg);
    -ms-transform: translateY(7px) rotate(-50deg);
    -o-transform: translateY(7px) rotate(-50deg);
    transform: translateY(7px) rotate(-50deg);
    -webkit-animation: downloadBounce2 800ms linear alternate infinite;
    -moz-animation: downloadBounce2 800ms linear alternate infinite;
    animation: downloadBounce2 800ms linear alternate infinite;
  }