.header{
    background:  #191650 !important ;
    padding:  0px;
    .headerNav{
        padding: 15px 0px;
    }
    .navbarLogo {
        width: 130px;
        position: absolute;
        z-index: 9999;
        transition: ease-in-out .3s;
        top: -23px;
    }
    .nav-link{
        padding: 10px 0px;
        margin: 0px 15px
    }
    .navbar{
        padding: 0px;
    }
    .navbar-brand{
        margin-right: 0px !important;
        margin-bottom: 0px !important;
        width: 140px;
        position: relative;
    }
    // .navbar-collapse{
    //     border-top: 1px solid rgba(255,255,255,.5);
    //     margin-top: 10px;
    // }

    .navbar-light .navbar-toggler{
        background: #FFF;
        padding: 2px 4px;
    }
    .navbar-light .navbar-nav .nav-link{
        color: #FFF;
        margin-bottom: 0px !important;
        
    }
    .navbar-nav{
        .active{
            color: #ffc107 !important;
        }
    }
}


.fixed-top{
    .navbarLogo{
        position: absolute;
        width: 70px;
        transition: ease-in-out .3s;
    }
}


.marqueeSection{
    background: #1950cd;
    padding: 10px;
    .overlay::before, .overlay::after{
        background: none !important;
    }
    .marqueeBox{
        display: grid;
        grid-gap: 10px;
        grid-template-columns: 38px 150px;
        font-size: 0.7rem; 
        color: #FFF;
        img{
            border-radius: 5px;
        }
    }
}


.registerBtn{
    background: linear-gradient(102deg,#ffba28 0%,#f4477e 100%);
    border-radius: 50px;
    font-size: 1rem;
    padding: 10px 30px;
    color: #FFF;
    border: none;
    cursor: pointer;
}



.navbar-light {
    .navbar-toggler{
        background: transparent !important;
        
    }
    .navbar-toggler-icon{
        background: url("../../assets/menu.png") !important;
        background-size: contain !important;
    }
}



.back-to-top {
    position: fixed;
     background: #b61e90;
    color: #fff;
    width: 44px;
    height: 44px;
    text-align: center;
    line-height: 1;
    font-size: 16px;
    border-radius: 50%;
    right: 15px;
    bottom: 15px;
    transition: background 0.5s;
    z-index: 11;
    svg{
        top: 13px !important;
        position: relative;
    }
}