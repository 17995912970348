.footer{    background: rgb(12 2 76); color: #FFF; padding: 90px 0px 30px 0px;}
ul{padding: 0px; list-style: none;}
a{color: #FFF; margin-bottom: 10px; display: block;}
a:hover{color: #FFF;}
.footer h3{ font-style: normal; font-weight: bold; font-size: 18px; color: #FFF; line-height: 22px; margin-bottom: 30px;}
.socialMedia{display: flex;}
.socialMedia a{margin-right: 15px;}
.copyrightFooter{background: rgb(12 2 76); color: #FFF; padding:15px 0px}
.copyrightFooter p{padding: 0px  !important; margin: 0px  !important;}
.footerLogo{margin-bottom: 15px;}

.footerText{
    margin-bottom: 0px !important;
    span{
        color: #f0d600;
    }
    a{    
        color: #b61e90;
        display: inline-block;
    }
}

.footerLogo{
    width: 100px;
}
.page-menu{
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    margin: 0px ;
    padding: 0px;
    li {
        width: 50%;
        margin-bottom: 20px;
        margin-left: 0px;
        margin-bottom:  0px !important;
        font-size: 14px !important;
        &:hover{
            a{color: #FFF !important;}
        }
        a {
            text-decoration: none;
            color: #7b76b5;
            text-transform: none;
            letter-spacing: 0;
            /* font-size: 14px !important; */
            font-weight: 500;
            transition: .2s ease-in-out all;
            
        }

    }
}
.payment-menu{
    li{
        margin-bottom: 15px !important;
    }
}