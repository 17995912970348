.faqPage{
    padding: 100px 0px;
    background : url(../../assets/images/backgrounds/inner-bg.jpg) center center
}
.faqsHeading{
    color:  #ff77ad !important; 
    padding-bottom: 15px;
    margin-bottom: 30px;
    border-bottom: 1px solid rgba(255,255,255,.4);
}
.tabsContainer{
    background: #0f0d3a;
     box-shadow: 0 0 50px #393364;

    border-radius: 50px;
    position: relative;
    box-shadow: 0 0 50px #393364;
    padding: 60px 50px;
    text-align: justify;
    @media (max-width: 768px) {
        padding: 15px;
    }
}


.tabheader {
    background: #211c5f;
    padding: 15px;
    border: 1px solid #2778ff;
    border-radius: 15px;
    text-align: center;
    @media (max-width: 768px) {
        display: grid;
        grid-template-columns: 1fr 1fr;
    }
    .react-tabs__tab {
        text-align: center;
        color: #FFF ;
        padding: 6px 27px !important;
        font-weight: 500;
          text-transform: uppercase;
       
    }
    .react-tabs__tab:focus:after{
        background: transparent;
    }
    img{
        display: block;
        margin:auto;
        margin-bottom: 10px;
        position: relative;
    }
    .react-tabs__tab--selected{
        background: transparent;
        border-color: transparent;
        color:  #ff77ad !important; 
        img{
            filter: invert(54%) sepia(86%) saturate(775%) hue-rotate(302deg) brightness(111%) contrast(104%);
        }
     }
}


.accordion__button{
    border-radius: 6px !important;
    margin-bottom: 15px !important;
}


.accordion__button{
    background-color: rgba(255,255,255,.1) !important;
    border-radius: 0;
    border: 5px;
    border-bottom: 0 solid rgba(255,255,255,.15);
    width: 100%;
    margin: 0 0 5px;
    color:#FFF !important;
}
.accordion__panel{
    color: #FFF !important;
}