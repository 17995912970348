.gameDetailPage{
    background: url(../../assets/images/backgrounds/game-bg.jpg) center center;
    background-size: cover;
    position: relative;
    padding: 140px 0;
    background-attachment: fixed;
}
.contactDescription{
    color: #8d88c3;

}

.gameDescriptionBox{
    display: grid;
    align-items: center;
}
.gameDetailImage{
    width: 200px;
}
.gameTitle{
    font-family: 'Montserrat', sans-serif;
    font-weight: 800;
    font-size: 2.4rem;
    color: #FFF;
}
.gameDescription {
    color: #8d88c3;
    font-family: 'Montserrat', sans-serif;
    font-size: 15px;
}


.regiterCTA{
    cursor: pointer;
    transition: .3s ease-in-out all;
    background: #ffba28;
    background: linear-gradient(102deg,#ffba28 0%,#f4477e 100%);
    border-image-slice: 0;
    border-image-source: linear-gradient(102deg,#ffba28 0%,#f4477e 100%);
    padding: 0.5rem 2rem;
    border-radius: 100px;
    font-size: .85rem;
    min-width: 110px;
    border: none;
    line-height: 1.8;
    font-weight: 500;
    text-transform: none;
    position: relative;
    color: #FFF !important;
    text-transform: uppercase; font-weight: 500 !important;   font-family: 'Montserrat', sans-serif;

    &:hover{
        transition: .3s ease-in-out all;
        background: #f4477e;
        background: linear-gradient(102deg,#f4477e 0%,#ffba28 100%);
        border-image-slice: 0;
        border-image-source: linear-gradient(102deg,#f4477e 0%,#ffba28 100%);
  
    }
}