.gamePage{
    background : url(../../assets/images/backgrounds/inner-bg.jpg) center center !important;
    background-size: cover;
    padding: 60px 0 40px 0;
    position: relative;
    background-attachment: fixed !important;
    .gameList{
        background: transparent;
    }
}
 