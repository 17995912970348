.home-hero{
    background: url(../../assets/images/backgrounds/title-bg.jpeg) repeat center center;
    background-size: cover;
    padding: 50px 0px;
    .heading{
        text-transform: uppercase;
        color: #fff;
        text-align: center;
        margin-right: auto;
        font-size: 2.6rem;
        font-weight: 800;
        text-shadow: #c8c8c8 1px 1px 0px, #b4b4b4 0px 2px 0px, #a0a0a0 0px 3px 0px, rgb(140 140 140 / 50%) 0px 4px 0px, #787878 0px 0px 0px, rgb(0 0 0 / 50%) 0px 5px 10px;
    
    
        @media (max-width: 768px) {
            font-size: 2rem;
        }
    }
    .description{
        color: #FFF;
        text-align: center;
    }
    .registerBtn{
        padding: 13px 30px !important;
        margin: auto !important;
        margin-top: 25px !important;
        display: block;
        width: 234px !important;
        transition: .3s ease-in-out all;
        background: #ffba28;
        background: linear-gradient(102deg,#ffba28 0%,#f4477e 100%);
        border-image-slice: 0;
        border-image-source: linear-gradient(102deg,#ffba28 0%,#f4477e 100%);
        border: none !important;
        color: #FFF !important;
        padding: 10px 30px !important;
        border-radius: 50px;
        font-size: 16px !important;
        font-family: "Nunito", sans-serif;
        font-weight: 500;
        text-align: center;
    }
}




.gameList{
    background: #191650;
    background-size: cover;
    padding: 60px 0 40px 0;
    position: relative;
    .tabLink{
        padding: 40px 0px;
        display: flex;
        margin: auto;
        @media (max-width: 768px) {
            display: block;
             text-align: center;
             li{
                display: inline-block;
             }
        }
       
        li{
            padding: 0px 30px;
            @media (max-width: 768px) {
                margin-bottom: 15px;
            }
           
        }
        .tabBtn{
            &:hover{
                img{
                    filter: invert(71%) sepia(90%) saturate(586%) hue-rotate(354deg) brightness(109%) contrast(105%);


                }
            }
            img{
                width: 45px !important;
                display: block;
                margin: auto;
            }
        }
     }
    .games-types-span {
        display: block;
        font-weight: 700;
        color: #ffa100;
        font-size: 1.1rem;
        text-transform: uppercase;
        letter-spacing: 3px;
        margin-top: 10px;
    }





    .portfolio-wrap {
        overflow: hidden;
        position: relative;
        display: block;
        &:hover{
        .portfoliio-image{
            &::after{
                opacity: 1;
                transition: ease-in-out .3s;
                background: #191650;
                background: linear-gradient(0deg,rgba(25,22,80,.8995973389) 0%,rgba(25,22,80,.5046393557) 70%,rgba(255,41,41,0) 100%);
            }
        }
        }
    }
    .portfoliio-image::after{
        background: none;
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        transition: ease-in-out .3s;
        border-radius: 30px;
    }
    #portfolio .portfolio-wrap:hover {
        box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.16);
    }
    .portfolio-caption h2 {
        color: #58c7fa;
        font-size: 16px;
        text-transform: uppercase;
        margin-bottom: 10px;
        font-weight: 600;
    }

    .portfolio-caption .rounded-button {
        padding: 9px 30px;
        border-radius: 29px;
        font-size: 15px;
        color: #FFF;
        width: 100px;
        background: #1eaee6;
        display: block;
        margin: auto;
    }
    .portfolio-wrap:hover .portfolio-caption{
        opacity: 1;
        position: absolute;
        bottom:20px;
        transition: ease-in-out .3s;
        transform: translate(0, 0px);
        
      }
      .portfolio-caption{
        position: absolute;
        opacity: 0;
        transition: ease-in-out .2s;
        transform: translate(0, 80px);
        width: 100%;
        padding: 10px !important;
        text-align: center;
      }
      .portfolio-caption h2{
        color: #58c7fa;
        font-size: 16px;
        text-transform: uppercase;
        margin-bottom: 10px;
        font-weight: 600;
      }
}





.playerguide{
    background: url(../../assets/images/backgrounds/game-bg.jpg) center center;
    background-size: cover;
    position: relative;
    background-attachment: fixed;
    padding: 100px 0px;
    ul{
        li{
            margin-bottom: 10px;
            svg{
                color: #0ceb17;
                margin-right: 15px;
            }
            color: #8d88c3;
        }
    }
    &::before {
        content: "";
        background: radial-gradient(circle,rgba(26,19,53,.4) 0%,rgba(37,41,115,.5) 100%);
        
        position: absolute;
        bottom: 0;
        top: 0;
        left: 0;
        right: 0;
    }
        .heading {
            text-transform: uppercase;
            color: #fff;
            margin-right: auto;
            font-size: 2.6rem;
            font-weight: 800;
            text-shadow: #c8c8c8 1px 1px 0px, #b4b4b4 0px 2px 0px, #a0a0a0 0px 3px 0px, rgb(140 140 140 / 50%) 0px 4px 0px, #787878 0px 0px 0px, rgb(0 0 0 / 50%) 0px 5px 10px;
        
            
        }
    
}








.homepagepromotion{
    background: url(../../assets/images/backgrounds/jaqport-bg.jpeg) center center !important;
    background-attachment: fixed !important;
    padding: 100px 0px;
    .heading{
        color: #fff;
        margin-right: auto;
        font-size: 1.8rem;
        font-weight: 800;
        text-transform: uppercase;
        text-shadow: #c8c8c8 1px 1px 0px, #b4b4b4 0px 2px 0px, #a0a0a0 0px 3px 0px, rgb(140 140 140 / 50%) 0px 4px 0px, #787878 0px 0px 0px, rgb(0 0 0 / 50%) 0px 5px 10px;
    }
    .description{
        color: #FFF;
    }
}







.promotionCard{
    background: #1a1744;
    border-radius: 10px;
    .promotionCaption{
        padding: 15px;
    }
    img{
        border-radius: 10px;
    }
    .promotionTitle{
        color: #FFF;
        font-size: 1rem;
 
        margin-bottom: 15px;
    }
    .promotionBlue{  
        background: #1eaee6;
        padding: 10px 15px;
        font-size: .8rem;
        border-radius: 50px;
        border:none;
        color: #FFF;
    }
}