.aboutSideImage{
 
        background: #232a5c;
        -webkit-border-radius: 15px;
        -moz-border-radius: 15px;
        border-radius: 15px!important;
        /* border-top-style: ridge; */
        border-bottom-style: solid;
        /* border-left-style: ridge; */
        border-right-style: solid;
        border-right-width: 10px;
        border-bottom-width: 10px;
        border-right-color: #2455a1;
        border-bottom-color: #2455a1;
 
}

.aboutpage{
    background: linear-gradient(rgb(24 21 80/48%),rgba(73,73,73,0.9)),url(../../assets/images/backgrounds/inner-bg.jpg) no-repeat center center;
    background-size: cover;
    padding: 140px 0!important;
    text-align: center;
    border: 1px ridge rgb(1 0 16);
    h2{
        color: rgb(160, 160, 160);
        font-size: 1.3rem;
    }
    p {
        color: #8d88c3;
        font-weight: 500;
         font-size: 1.1rem;
        text-align: justify;
    }
}