.messageSent{
    display: block;
    margin: auto;
    width: 100px;
}
.errormsg{
    color: red !important;
}
.closeBtn{
    display: block;
    margin: auto;
    border: none;
    padding: 10px 20px;
}
.popupmessage{
    font-size: 20px;
} 

.form-group{
    color: #8d88c3;
}
.form-control{
    background-color: #ffffff1a;
    border: none;
    color: #FFF !important;
    font-size: 14px;
    &:focus{
        background-color: #ffffff1a;
    }
}
.formSubmitButton{
    transition: .3s ease-in-out all;
    background: #ffba28;
    background: linear-gradient(102deg,#ffba28 0%,#f4477e 100%);
    border-image-slice: 0;
    border-image-source: linear-gradient(102deg,#ffba28 0%,#f4477e 100%);
    border: none!important;
    color: #fff!important;
    padding: 10px 30px!important;
    border-radius: 50px;
    font-size: 16px!important;
    display: block;
    margin: auto;
}

.inputStyle{
    option{
        color: #000;
    }
}